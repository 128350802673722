<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">votes and proceedings</div>
      </v-row>
      <v-row class="">
        <div class="">
          Precise summary of the proceedings of each sitting. The summary
          contains a record of what was done or transacted at the sitting in the
          order in which they were dealt with or occurred
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-autocomplete
          label="Member"
          outlined
          :items="councilMemberItems"
          prepend-icon="mdi-account"
        ></v-autocomplete>

        <v-autocomplete
          label="Commitee"
          :items="commiteeItems"
          outlined
          prepend-icon="mdi-account-group"
        ></v-autocomplete>

        <v-autocomplete
          label="Vote Type"
          :items="voteTypeItems"
          outlined
          prepend-icon="mdi-vote"
        ></v-autocomplete>
      </v-card-title>
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>

        <v-autocomplete
          prepend-icon="mdi-ballot"
          label="Vote Response"
          :items="voteResponseItems"
          outlined
        ></v-autocomplete>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date"
              @click:row="pdfDialog = true"
            >
            </v-data-table>

            <v-dialog v-model="pdfDialog">
              <pdf-viewer />
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../PdfViewer.vue";
export default {
  components: { PdfViewer },
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      pdfDialog: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      councilMemberItems: [],
      commiteeItems: [],
      voteTypeItems: [],
      voteResponseItems: [],
      headers: [
        {
          text: "meeting body",
          value: "meetingBody",
          class: "text-uppercase black--text",
        },
        {
          text: "meeting date",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "file number",
          value: "file",
          class: "text-uppercase black--text",
        },
        {
          text: "subject",
          value: "subject",
          width: "14%",
          class: "text-uppercase black--text",
        },
        {
          text: "action item",
          value: "action",
          width: "10%",
          class: "text-uppercase black--text",
        },
        {
          text: "member",
          value: "member",
          class: "text-uppercase black--text",
        },
        {
          text: "vote type",
          value: "voteType",
          class: "text-uppercase black--text",
        },
        {
          text: "member vote",
          value: "vote",
          class: "text-uppercase black--text",
        },
        {
          text: "result",
          value: "result",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          meetingBody: "audit commitee",
          date: "Jun 10, 3019",
          file: "2021-003",
          subject:
            "311/Public Works Sidewalk Snow and Ice Removal Consultation Report",
          action: "Directing staff to publish the report.",
          member: "johnson",
          voteType: "Roll call",
          vote: "Aye",
          result: "Passed",
        },
        {
          meetingBody: "audit commitee",
          date: "Jun 10, 3019",
          file: "2021-003",
          subject:
            "311/Public Works Sidewalk Snow and Ice Removal Consultation Report",
          action: "Directing staff to publish the report.",
          member: "johnson",
          voteType: "Roll call",
          vote: "Aye",
          result: "Passed",
        },
        {
          meetingBody: "audit commitee",
          date: "Jun 10, 3019",
          file: "2021-003",
          subject:
            "311/Public Works Sidewalk Snow and Ice Removal Consultation Report",
          action: "Directing staff to publish the report.",
          member: "johnson",
          voteType: "Roll call",
          vote: "Aye",
          result: "Passed",
        },
      ],
    };
  },
};
</script>

<style></style>
